import gql from 'graphql-tag';

export const APP_GET_EXECUTIES_SAVE_SEARCHS_BY_PROFILE_ID = gql`
  query appGetExecutiesSaveSearchsByProfileId($profile_id: String!, $weeklyStartDate: String!, $weeklyEndDate: String!) {
    appGetExecutiesSaveSearchsByProfileId(profileId:$profile_id, weeklyStartDate:$weeklyStartDate, weeklyEndDate:$weeklyEndDate) 
    {
      firmName
      profileData {
        name {
          fullName
        }
        id
        firmId
        email
      }
      searchRecords {
        excludedIds
        id
        name
        searchedParameters {
          executive {
            industrySectors
            industrySectorsCompressed
            willingToRelocate
            positionsOfInterest {
              fullTimeFirm
              fullTimeOwned
              partTimeAdvisor
            }
            experience {
              highestTitle
              plSize
              plSizeCompressed
            }
            expertise {
              highestLevel
            }
            willingToRelocate
            keyword
          }
        }
        savedBy {
          firmId
          userId
        }
      }
      executives {
        searchId
        executivesData {
          id
          name {
            fullName
          }
          experienceAggregate {
            currentTitle
            exclusiveTitle
            plSize
            title
            companyName
            plSizeCompressed
            plResponsibility
          }
          expertise {
            sponsored
          }
          industrySectors
          industrySectorsCompressed
        }
      }
    }
  }
`;

export const APP_GET_PROFILE_BY_NAME = gql`
  query appGetProfileByName($search_name_query: String!) {
    appGetProfileByName(searchNameQuery:$search_name_query) 
    {
      id
      industrySectors
      email
      name {
        fullName
      }
      experienceAggregate {
        currentTitle
        exclusiveTitle
        plSize
        title
        companyName
        plSizeCompressed
        plResponsibility
      }
    }
  }
`;

export const APP_FIRMS_SEARCH_LISTS = gql`
  query appFirmsSearchLists {
    appFirmsSearchLists {
      firmId
      firmName
      totalCount
      searchRecords {
        id
        name
      }
    }
  }
`;
