<template>
  <div>
    <p>
      This is an in-house project designed to help our team streamline tasks
      and expedite work processes efficiently.
    </p>
    <div class="row">
      <!-- ZohoMail Authentication Card -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">ZohoMail Authentication</h5>
            <p class="card-text" v-if="isMailConnected">You are connected.</p>
            <p class="card-text" v-else>
              <a href="#" @click.prevent="connectNow('ZohoMail')">Connect Now</a>
            </p>
          </div>
        </div>
      </div>

      <!-- ZohoCRM Authentication Card -->
      <div class="col-md-4" v-if="username === 'naeemnn'">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">ZohoCRM Authentication</h5>
            <p class="card-text" v-if="isCRMConnected">You are connected.</p>
            <p class="card-text" v-else>
              <a href="#" @click.prevent="connectNow('ZohoCRM')">Connect Now</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter
import { useQuery } from '@urql/vue';
import { GET_USER_ACCESS_TOKEN } from '../graphql/zoho_auth_queries';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter(); // Initialize Vue Router
    const isMailConnected = ref(false);
    const isCRMConnected = ref(false);
    const mailCheckCompleted = ref(false); // Tracks completion of ZohoMail check
    const username = 'naeemnn'; // Replace with dynamic username if needed

    // Reusable function for "Connect Now"
    const connectNow = (tokenType) => {
      router.push({ name: 'ZohoZohoAuth', query: { tokenType } });
    };

    // UseQuery for ZohoMail
    const {
      data: mailData,
      error: mailError,
      executeQuery: executeMailQuery,
    } = useQuery({
      query: GET_USER_ACCESS_TOKEN,
      variables: { username, tokenType: 'ZohoMail' },
    });

    // UseQuery for ZohoCRM (initially paused)
    const {
      data: crmData,
      error: crmError,
      executeQuery: executeCRMQuery,
    } = useQuery({
      query: GET_USER_ACCESS_TOKEN,
      variables: { username, tokenType: 'ZohoCRM' },
      pause: true,
    });

    // Handle ZohoMail Authentication
    watchEffect(() => {
      if (mailData.value) {
        if (mailData.value.checkZohoToken.accessTokenExists) {
          isMailConnected.value = true;
        } else {
          console.error('ZohoMail token does not exist.');
        }
        mailCheckCompleted.value = true;
        executeCRMQuery();
      }
      if (mailError.value) {
        console.error('ZohoMail Authentication Error:', mailError.value);
        mailCheckCompleted.value = true;
        executeCRMQuery();
      }
    });

    // Handle ZohoCRM Authentication
    watchEffect(() => {
      if (crmData.value) {
        if (crmData.value.checkZohoToken.accessTokenExists) {
          isCRMConnected.value = true;
        } else {
          console.error('ZohoCRM token does not exist.');
        }
      }
      if (crmError.value) {
        console.error('ZohoCRM Authentication Error:', crmError.value);
      }
    });

    onMounted(() => {
      document.getElementById('header-title').innerText =
        'Welcome to Hal App Dashboard';
      executeMailQuery(); // Start the ZohoMail query on mount
    });

    return {
      isMailConnected,
      isCRMConnected,
      username,
      connectNow, // Expose connectNow for use in the template
    };
  },
};
</script>
