import { GET_USER_ACCESS_TOKEN } from '../graphql/zoho_auth_queries';
import { useQuery } from '@urql/vue';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default function ZohoMailAuthChecker(username, tokenType = 'ZohoMail') {
  const router = useRouter();
  const zohoAuthToken = ref(null);  // Initialize as null to avoid undefined access
  const tokenError = ref(null);

  // Query for user access token with the provided tokenType
  const { data: queryData, error: queryError } = useQuery({
    query: GET_USER_ACCESS_TOKEN,
    variables: { username, tokenType },
  });
  // Watch the query result to handle the token data
  watchEffect(() => {
    if (queryError.value) {
      tokenError.value = queryError.value;
      console.error('Error fetching user token:', queryError.value);
      return;
    }
    if (queryData.value && queryData.value.checkZohoToken) {
      const tokenData = queryData.value.checkZohoToken;
      //console.log("tokenData:::",tokenData);
      if (tokenData.accessTokenExists) {
        zohoAuthToken.value = tokenData;
      } else {
        router.push({ name: 'ZohoZohoAuth', query: { tokenType } });
      }
    }
  });

  return { zohoAuthToken, tokenError };
}
